import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="delayed-class-toggle"
export default class extends Controller {
  static values = {
    delay: Number, // Delay in milliseconds
    class: String, // Classname to toggle
  };

  connect() {
    setTimeout(() => {
      this.element.classList.toggle(this.classValue);
    }, this.delayValue);
  }
}
